import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CertedLogo from "../Assets/Logo/CertedLogo";

const BodyText = ({ title, body, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: 338,
        height: 153,
        backgroundColor: "#FFFFFF",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 12,
        borderColor: "#94BAFD",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 31,
        paddingLeft: 22,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          fontWeight: 500,
          fontSize: 24,
        }}
      >
        {title}
      </div>
      <div
        style={{
          width: 312,
          border: 1,
          borderColor: "#E3E3E3",
          borderStyle: "solid",
          marginTop: 11,
          marginBottom: 12,
        }}
      />
      <div
        style={{
          fontWeight: 400,
          fontSize: 20,
          width: 312,
          lineHeight: 1.5,
        }}
      >
        {body}
      </div>
    </div>
  );
};

const PLSelectMemberType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { token, investorName } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const investorName = queryParams.get("investorName");
  console.log("queryParams : ", queryParams);
  console.log("token : ", token);
  console.log("investorName : ", investorName);
  const nonMemberProcess = () => {
    // TODO:  이동하기 전에 token을 통해서 연동이 완료된 회원인지 조회하기. 그렇게 하면 인증 완료 후에 중복 인증 불가능.

    navigate("/plus/process", {
      state: {
        type: "nonMember",
        token: token,
      },
    });
  };

  const memberProcess = () => {
    navigate("/plus/process", {
      state: {
        type: "member",
        token: token,
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw", // 전체 화면 너비
        height: "100vh", // 전체 화면 높이
        backgroundColor: "#f0f0f0", // 원하는 배경색
      }}
    >
      <div
        style={{
          position: "fixed", // 고정 위치 설정

          width: 81,
          height: 29,
          top: 0,
          left: 0,
          marginTop: 11,
          marginLeft: 20,
        }}
      >
        <CertedLogo />
      </div>

      <div
        style={{
          whiteSpace: "pre-wrap",
          fontFamily: "Pretendard",
          fontWeight: 500,
          fontSize: 36,
          color: "#464646",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: 768,
            lineHeight: 1.5,
          }}
        >
          <div>{`${investorName || "써티드"}의 투자사 연동 서비스 입니다. \n써티드의 기존 회원이신가요?`}</div>
        </div>
        <div
          style={{
            marginTop: 32,
            display: "flex",
            width: 768,
            height: 184,
            flexDirection: "row",
            justifyContent: "space-between",
            color: "#464646",
          }}
        >
          <BodyText onClick={memberProcess} title={"기존 회원"} body={"써티드의 아이디와 비밀번호를 입력하여 투자사와 연동할 수 있습니다."} />
          <BodyText onClick={nonMemberProcess} title={"비회원"} body={"써티드에 회원가입을 하여 투자사와 연동할 수 있습니다."} />
        </div>
      </div>
    </div>
  );
};

export default PLSelectMemberType;
