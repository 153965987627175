import "../GlobalStyle.css";
import "../PCStyle.css";
import InputShort from "../Components/InputShort";
import RegistrationTypeTitle from "../Components/RegistrationTypeTitle";
import axios from "axios";
import { gloablAPI } from "../Utils/globalAPI";

const PLLinkedWebDetails1 = ({ RegistrationTypeTitleData, setSignin1, setSignin2, companyIdEmail, setCompanyIdEmail, companyPassword, setCompanyPassword, setIsLoading }) => {
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPasswordValid = (password) => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?^()&])[A-Za-z\d@$!%*^()#?&]{8,}$/;
    return passwordPattern.test(password);
  };

  const nextOnClick = async () => {
    if (isValidEmail(companyIdEmail) && isPasswordValid(companyPassword)) {
      setIsLoading(true);
      try {
        const endPoint = `${gloablAPI}/product/member/assignment/member`;
        const response = await axios.get(endPoint, {
          params: {
            email: companyIdEmail,
            password: companyPassword,
          },
        });

        if (response.data.status === "아이디, 비밀번호 조회 성공") {
          setIsLoading(false);
          setSignin1(false);
          setSignin2(true);
        } else if (response.data.status === "아이디 조회 실패") {
          setIsLoading(false);
          alert("해당 아이디는 써티드에 가입된 아이디가 아닙니다.\n아이디를 확인하신 후 다시 한번 시도해 주세요.");
        } else if (response.data.status === "비밀번호 불일치") {
          setIsLoading(false);
          alert("비밀번호가 일치하지 않습니다.\n비밀번호를 확인하신 후 다시 한번 시도해 주세요.");
        } else {
          setIsLoading(false);
          alert("써티드 회원 여부를 확인할 수 없습니다.\n아이디와 비밀번호를 확인하신 후 다시 한번 시도해 주세요.");
        }
      } catch (error) {
        console.log("Failed to send e-mail request for investment company cooperation : ", error);
        setIsLoading(false);
        alert("피투자사 연동 요청 메일 발송 통신이 실패했습니다.\n다시 한번 시도해주세요.");
      }
    } else {
      alert("누락된 값 입력 필요.");
    }
  };

  return (
    <>
      <RegistrationTypeTitle
        type={"integrate"}
        info={RegistrationTypeTitleData[0]}
        nextOnClick={nextOnClick}
        isProgressNext={isValidEmail(companyIdEmail) && isPasswordValid(companyPassword) ? true : false}
      />
      <div>
        <InputShort
          placeholder={"이메일 주소를 입력해주세요."}
          required
          requiredCondition={isValidEmail(companyIdEmail)}
          label={"아이디"}
          value={companyIdEmail}
          onChange={setCompanyIdEmail}
        />
        <InputShort
          placeholder={"비밀번호를 입력해주세요."}
          required
          requiredCondition={isPasswordValid(companyPassword)}
          label={"비밀번호"}
          type={"password"}
          value={companyPassword}
          onChange={setCompanyPassword}
        />
      </div>
    </>
  );
};

export default PLLinkedWebDetails1;
